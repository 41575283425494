<template>
  <div>
    <!-- <!- 零售分析商品对比分析 -> -->
    <filter-view :loading="loading" @submit="loadEchart">
      <retail-Filter :show-opt="showOpt" :query="query" :rank="rank" @init="loadEchart" />
    </filter-view>
    <div class="result">
      <div class="padding-10 fs-small fc-g">分析结果</div>
      <div class="round-panel bc-w">
        <div v-if="store && store.length">
          <div class="c h sb">
            <div class="no-flex padding-10">分析对象</div>
            <el-radio-group v-model="query.isSendObject" size="mini" @change="draw">
              <el-radio :label="true">出库量</el-radio>
              <el-radio :label="false">销售量</el-radio>
            </el-radio-group>
          </div>
          <div class="c h sb sep-t">
            <div class="no-flex padding-10">排序依据</div>
            <el-radio-group v-model="query.isGoodsMoney" size="mini" @change="draw">
              <el-radio :label="true">商品金额</el-radio>
              <el-radio :label="false">商品数量</el-radio>
            </el-radio-group>
          </div>
          <div class="sep-t" ref="chart" style="height:320px;"></div>
        </div>
        <div class="fc-g ta-c padding-10" v-else>没有分析数据，请更改统计分析条件或稍候重试。</div>
      </div>
      <template v-if="store && store.length > 0">
        <div class="padding-10 fs-small fc-g">数据列表</div>
        <div class="round-panel bc-w">
          <div v-for="o in pagedStore" :key="o.id" class="padding-05 sep-b">
            <div class="fs-large bold padding-05">{{ o.name }}</div>
            <div class="h c wrap fs-small lh-150">
              <div class="padding-05" style="width: 50%">
                销售金额：{{ $price(o.saleMoneyA) }}
                <br />
                对比金额：{{ $price(o.saleMoneyB) }}
                <br />
                <div>
                  <span v-if="o.saleMoneyRatio">配比&nbsp;{{ o.saleMoneyRatio.toFixed(2) }}</span>
                  <span v-else>配比&nbsp;-</span>
                </div>
              </div>
              <div class="padding-05" style="width: 50%">
                销售数量：{{ o.saleNumberA || "-" }}
                <br />
                对比数量：{{ o.saleNumberB || "-" }}
                <br />
                <div>
                  <span v-if="o.saleNumberRatio">配比&nbsp;{{ o.saleNumberRatio.toFixed(2) }}</span>
                  <span v-else>配比&nbsp;-</span>
                </div>
              </div>
              <div class="padding-05" style="width: 50%">
                发货金额：{{ $price(o.sendMoneyA) }}
                <br />
                对比金额：{{ $price(o.sendMoneyB) }}
                <br />
                <div>
                  <span v-if="o.sendMoneyRatio">配比&nbsp;{{ o.sendMoneyRatio.toFixed(2) }}</span>
                  <span v-else>配比&nbsp;-</span>
                </div>
              </div>
              <div class="padding-05" style="width: 50%">
                发货数量：{{ o.sendNumberA || "-" }}
                <br />
                对比数量：{{ o.sendNumberB || "-" }}
                <br />
                <div>
                  <span v-if="o.sendNumberRatio">配比&nbsp;{{ o.sendNumberRatio.toFixed(2) }}</span>
                  <span v-else>配比&nbsp;-</span>
                </div>
              </div>
            </div>
          </div>
          <div class="ta-c">
            <el-pagination class="h5-pagination" :total="totalElements" :current-page.sync="page" :page-size="size" layout="prev, pager, next" hide-on-single-page />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import retailFilter from "../assembly/filter/retail";
import request from "@/utils/request";
export default {
  components: {
    filterView,
    retailFilter,
  },
  data() {
    let now = new Date();
    return {
      loading: false,
      page: 1,
      size: 6,
      totalElements: null,
      store: [],
      chart: null,
      showOpt: {
        showGoodsType: false, //商品分类
        showCategory: true, //对比类型
        showTimeRange: true, //时间范围
        showRangGoods: true, //商品范围
        showRank: true, //显示排名对象
        showDimension: false, //分析维度隐藏
        showReported: false, //仅显示已上报
        showRankGist: false, //显示排名依据
        showCommodity: false, //指定商品
        showLevel: false, //汇总级别
        showYearonyear: false, //同比显示
        showMonth: false, //环比显示
        showCompareyear: false, //同比年度
        showDetail: false, //显示零售明细查询
      },
      query: {
        addressType: "area",
        dateGroup: "day",
        isGoodsMoney: true,
        isSendObject: false,
        area: {
          treeNodeId: "1",
          treeNodeType: "group",
        },
        standard: {
          treeNodeId: "",
          StandardAreaGroup: "country", //标准行政区域
        },
        begDate: new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        ).getTime(),
        endDate: new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          23,
          59,
          59
        ).getTime(),
        yearOnYearShow: false,
        compareMonth: false,
        brandId: null,
        seriesId: null,
        categoryId: null, //请选择分类1
        categoryIdB: null, //请选择分类2
        // AreaGroupType: "saleValueGroup", //排名对象
        // shopAreaGroup: "area", //排名对象
        page: 0,
        size: 10000,
      },
      rank: {
        rankDefines: [
          {
            name: "区域",
            value: { AreaGroupType: "area", shopAreaGroup: "area" },
          },
          {
            name: "经销商",
            value: { AreaGroupType: "area", shopAreaGroup: "distributor" },
          },
          {
            name: "省",
            value: { AreaGroupType: "standard", cityAreaGroup: "province" },
          },
          {
            name: "市",
            value: { AreaGroupType: "standard", cityAreaGroup: "city" },
          },
        ],
        multParams: 0,
      },
    };
  },
  watch: {
    store() {
      this.page = 1;
    },
  },
  computed: {
    pagedStore() {
      let start = (this.page - 1) * this.size,
        len = this.size;
      if (start + len > this.store.length) {
        len = this.store.length - start;
      }
      return this.store.slice(start, start + len);
    },
  },

  methods: {
    makeParams() {
      let data = JSON.parse(JSON.stringify(this.query));
      Object.assign(data, this.rank.rankDefines[this.rank.multParams].value);
      data.begDate = new Date(data.begDate);
      data.endDate = new Date(data.endDate);
      data.begDate = new Date(
        data.begDate.getFullYear(),
        data.begDate.getMonth(),
        data.begDate.getDate()
      ).getTime();
      data.endDate = new Date(
        data.endDate.getFullYear(),
        data.endDate.getMonth(),
        data.endDate.getDate() + 1
      ).getTime();
      
      if (data.addressType == "standard") {
        Object.assign(data, data.standard);
      } else {
        Object.assign(data, data.area);
      }
      delete data.standard;
      delete data.area;

      if (data.goods) data.skuId = data.goods.id;
      delete data.goods;
      delete data.compareYear;
      return data;
    },
    loadEchart() {
      let data = Object.assign({}, this.makeParams());
      this.loading = true;
      request({
        url: "@host:analysis;api/analy/retail/shop/sale/goods/contrast",
        method: "get",
        params: data,
      })
        .then((res) => {
          this.store = res;
          this.totalElements = res.length;
          this.$nextTick((_) => {
            this.draw();
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    draw() {
      if (this.store && this.store.length) {
        let series = [];
        let legend = [];
        let xAxisData = [];
        let aCol = {};
        let bCol = {};
        let cCol = {};
        let yName = "";
        let yFormat = this.$price;
        if (this.query.isSendObject == true) {
          if (this.query.isGoodsMoney == true) {
            aCol.text = "类型1发货金额";
            aCol.key = "sendMoneyA";
            bCol.text = "类型2发货金额";
            bCol.key = "sendMoneyB";
            cCol.text = "发货金额配比";
            cCol.key = "sendMoneyRatio";
            yName = "元";
            yFormat = this.$price;
          } else {
            aCol.text = "类型1发货数量";
            aCol.key = "sendNumberA";
            bCol.text = "类型2发货数量";
            bCol.key = "sendNumberB";
            cCol.text = "发货数量配比";
            cCol.key = "sendNumberRatio";
            yName = "件";
            yFormat = "{value}";
          }
        } else {
          if (this.query.isGoodsMoney == true) {
            aCol.text = "类型1销售金额";
            aCol.key = "saleMoneyA";
            bCol.text = "类型2销售金额";
            bCol.key = "saleMoneyB";
            cCol.text = "销售金额配比";
            cCol.key = "saleMoneyRatio";
            yName = "元";
            yFormat = this.$price;
          } else {
            aCol.text = "类型1销售数量";
            aCol.key = "saleNumberA";
            bCol.text = "类型2销售数量";
            bCol.key = "saleNumberB";
            cCol.text = "销售数量配比";
            cCol.key = "saleNumberRatio";
            yName = "件";
            yFormat = "{value}";
          }
        }

        this.store = this.store.sort((a, b) => {
          return (b[cCol.key] || 0) - (a[cCol.key] || 0);
        });

        legend.push(aCol.text);
        legend.push(bCol.text);
        legend.push(cCol.text);
        let nodeA = {
          name: aCol.text,
          type: "bar",
          stack: "合计",
          data: [],
        };
        let nodeB = {
          name: bCol.text,
          type: "bar",
          stack: "合计",
          data: [],
        };
        let nodeC = {
          name: cCol.text,
          yAxisIndex: 1,
          type: "line",
          data: [],
        };

        this.store.forEach((d) => {
          xAxisData.push(d.name);
          if (yName === "元") {
            let a = (d[aCol.key] || 0) / 100;
            a = a.toFixed(2);
            nodeA.data.push(a);
            a = (d[bCol.key] || 0) / 100;
            a = a.toFixed(2);
            nodeB.data.push(a);
          } else {
            let a = d[aCol.key] || 0;
            a = a.toFixed(2);
            nodeA.data.push(a);
            a = d[bCol.key] || 0;
            a = a.toFixed(2);
            nodeB.data.push(a);
          }

          let c = d[cCol.key] || 0;
          c = c.toFixed(2);
          nodeC.data.push(c);
        });
        series.push(nodeA);
        series.push(nodeB);
        series.push(nodeC);
        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light");
        }
        this.chart.clear();
        this.chart.setOption({
          tooltip: {
            trigger: "axis",
            confine: true,
            axisPointer: {
              type: "shadow",
            },
          },
          legend: {
            data: legend,
          },
          grid: {
            left: 85,
            right: 70,
            bottom: 50,
            top: 50,
          },
          xAxis: [
            {
              type: "category",
              data: xAxisData,
              axisLabel: {
                formatter: function (value) {
                  if (value.length > 5) {
                    return value.substr(0, 5) + "…";
                  }
                  return value;
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              name: yName,
              // axisLabel: {
              //   formatter: yFormat,
              // },
            },
            {
              type: "value",
              name: "配比（倍）",
              axisLabel: {
                formatter: "{value}",
              },
            },
          ],
          series: series,
          dataZoom: {
            show: xAxisData.length > 5, // 为true 滚动条出现
            filterMode: "weakFilter",
            type: "slider", // 有type这个属性，滚动条在最下面，也可以不行，写y：36，这表示距离顶端36px，一般就是在图上面。
            height: 15,
            start: 0,
            end: (5 / xAxisData.length) * 100,
            bottom: 10,
            handleIcon:
              "M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
            handleSize: "80%",
            zoomLock: true,
            showDetail: false,
          },
        });
      } else {
        this.chart = null;
      }
    },
  },
};
</script>